<script>
import { mapState } from 'vuex'

import { toastMixins } from '@/mixins'
import userService from '@/services/user/user'
import authService from '@/services/auth/auth'

import MenuBar from './components/MenuBar.vue'

import CreateEditForm from '../components/CreateEditForm.vue'

export default {
  mixins: [toastMixins],
  components: { MenuBar, CreateEditForm },
  data() {
    return {
      userId: null,
      userLogonMaster: null,
      isLoading: false,
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      defaultDelayMs: (state) => state.constants.defaultDelayMs
    })
  },
  created() {
    this.userId = this.$route.params.userId
    this.fetchUserLogonMaster()
  },
  methods: {
    async fetchUserLogonMaster() {
      this.isLoading = true
      try {
        const res = await userService.getUserLogonMaster(this.userId)
        this.userLogonMaster = res.data.data

        if (this.userLogonMaster.userLevel === '99.99') {
          this.$router.replace({
            name: 'Admin.UserLogonMaster.EditAdmin',
            params: {
              userId: this.userId
            }
          })
        }
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      }
      this.isLoading = false
    },
    async updatedInfoHandler(payload) {
      this.isLoading = true
      try {
        await userService.updateUserLogonMaster(this.userId, payload)
        this.mxDisplaySuccessMessage('แก้ไขข้อมูลผู้ใช้งานสำเร็จ')
        this.isLoading = false
        setTimeout(() => {
          this.$router.push(`/admin/user-logon-masters/detail/${this.userId}`)
        }, this.defaultDelayMs * 0.5)
      } catch (err) {
        this.errMsg = err.response.data.thMessage
        this.isLoading = false
      }
    },
    async updatedGroupHandler(payload) {
      this.isLoading = true
      try {
        await authService.setGroupCodesForUserId({
          userId: this.userId,
          allowGroupCodes: [...payload]
        })
        this.mxDisplaySuccessMessage('แก้ไขข้อมูลกลุ่มผู้ใช้งานสำเร็จ')
        this.isLoading = false
        setTimeout(() => {
          this.$router.push(`/admin/user-logon-masters/detail/${this.userId}`)
        }, this.defaultDelayMs * 0.5)
      } catch (err) {
        this.errMsg = err.response.data.thMessage
        this.isLoading = false
      }
    },
    async updatedPermissionHandler(payload) {
      this.isLoading = true
      try {
        await Promise.all(
          payload.map((el) => {
            const {
              systemMenuAccessId,
              allowPermissions,
              notAllowPermissions
            } = el
            return authService.updateSystemMenuAccess(systemMenuAccessId, {
              allowPermissions,
              notAllowPermissions
            })
          })
        )
        this.mxDisplaySuccessMessage(
          'แก้ไขข้อมูลการจำกัดสิทธิ์ของผู้ใช้งานสำเร็จ'
        )
        this.isLoading = false
        setTimeout(() => {
          this.$router.push(`/admin/user-logon-masters/detail/${this.userId}`)
        }, this.defaultDelayMs * 0.5)
      } catch (err) {
        this.errMsg = err.response.data.thMessage
        this.isLoading = false
      }
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- แถบ menu ด้านบน -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="แก้ไขรายละเอียดผู้ใช้งาน"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar></menu-bar>
      </b-col>
    </b-row>

    <!-- edit form -->
    <create-edit-form
      modeProp="EDIT"
      :userLogonMasterProp="userLogonMaster"
      :isLoadingProp="isLoading"
      @updatedInfo="updatedInfoHandler"
      @updatedGroup="updatedGroupHandler"
      @updatedPermission="updatedPermissionHandler"
    ></create-edit-form>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style></style>
